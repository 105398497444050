function isBracket(a) {
  if (typeof a === 'object' && a !== null) {
    return true
  }

  return false
}

export const methods = {
  link_open(e) {
    e.preventDefault()

    let component = this

    const plaid = this.plaid
    if (!isBracket(plaid)) {
      return
    }

    // destroy link handler to prevent stacking of iframes
    if (this.linkHandler != null) {
      this.linkHandler.destroy()
      this.linkHandler = null
    }

    console.log({
      clientName: this.clientName,
      env: this.env,
      isWebview: this.isWebview,
      key: this.public_key,
      product: this.products,
      receivedRedirectUri: this.receivedRedirectUri,
      token: this.link_token,
      //
      // Optional – use webhooks to get transaction and error updates
      webhook: this.webhook
    })

    this.linkHandler = this.plaid.create({
      clientName: this.clientName,
      env: this.env,
      isWebview: this.isWebview,
      key: this.public_key,
      product: this.products,
      receivedRedirectUri: this.receivedRedirectUri,
      token: this.link_token,
      //
      // Optional – use webhooks to get transaction and error updates
      webhook: this.webhook,
      //
      //\________________
      // *optional
      // 	  called when link loads
      onLoad: function() {
        component.onLoad()
      },
      onSuccess: function(public_token, metadata) {
        //
        // "metadata" contains info about the institution the
        // user selected and the account ID or IDs, if the
        // Select Account view is enabled.
        //
        component.onSuccess(public_token, metadata)
      },
      onExit: function(err, metadata) {
        if (err != null) {
          console.error('plaid onExit error', err)
          // Plaid API error occurred prior to exiting?
        }
        //
        // metadata contains information about the institution
        // that the user selected and the most recent API request IDs.
        //
        // ? Storing this information can be helpful for support?
        //
        component.onExit(err, metadata)
      },
      onEvent: function(eventName, metadata) {
        // Optionally capture Link flow events, streamed through
        // this callback as your users connect an Item to Plaid.
        // For example:
        // eventName = "TRANSITION_VIEW"
        // metadata  = {
        //   link_session_id: "123-abc",
        //   mfa_type:        "questions",
        //   timestamp:       "2017-09-14T14:42:19.350Z",
        //   view_name:       "MFA",
        // }

        component.onEvent(eventName, metadata)
      }
    })

    this.linkHandler.open()
  }
}
