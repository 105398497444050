//
//
//
import { mapGetters, mapState } from 'vuex'
//
export const computed = {
  ...mapGetters('hoa', ['financialInstitutionId', 'plaidInstitutionId']),
  ...mapGetters('user', ['currentHoaId']),
  ...mapState({
    hoaId: state => state.user.selectedHoaId,
    financialInstitutionId: state => state.bankaccount.selectedFinancialInstitutionId,
    plaidInstitutionId: state => state.bankaccount.selectedPlaidInstitutionId,
    plaidAccountIds: state => state.bankaccount.selectedPlaidAccountIds
  })
}
