/*
  example:
    import { createOrRetrievePlaidAccessToken } from '@/services/CheckbookReconciliation/FinancialInstitution/CreateOrRetrievePlaidAccessToken';

    const { successful, message } = await createOrRetrievePlaidAccessToken ({
      authorization,
      json: {
        hoaID,
        bankDataProviderID,
        institutionID,
        bankName,
        publicToken
      }
    });
    if (!successful) {
      return;
    }
*/

//
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'
//
export async function createOrRetrievePlaidAccessToken({ params, json } = {}) {
  let message = `??`

  const { hoaID, bankDataProviderID, institutionID, bankName, publicToken } = params
  const _params = [
    ['hoaID', hoaID],
    ['bankDataProviderID', bankDataProviderID],
    ['institutionID', institutionID],
    ['bankName', bankName],
    ['publicToken', publicToken]
  ]
    .map(e => {
      return e[0] + '=' + e[1]
    })
    .join('&')

  const path = `/CheckbookReconciliation/FinancialInstitution/CreateOrRetrievePlaidAccessToken?${_params}`

  try {
    const result = await kms.post(path, json)

    return {
      successful: true,
      message: '',
      data: result
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem(message)

  return {
    successful: false,
    message,
    data: null
  }
}

///////
