//
//
import { bankDataProviderStore } from '@/services/BankDataProvider/store'
import { utilityStore } from '@/services/Utility/store'
import { notifyError, notifyMessage } from '@/services/notify'
//
import { createOrRetrievePlaidAccessToken } from '@/services/CheckbookReconciliation/FinancialInstitution/CreateOrRetrievePlaidAccessToken'
//
import { mapActions } from 'vuex'
//
export const methods = {
  ...mapActions('bankaccount', [
    'selectFinancialInstitutionId',
    'selectPlaidInstitutionId',
    'selectPlaidAccountIds'
  ]),

  async getCredentialByNames() {
    await utilityStore
      .dispatch('getCredentialsMultiByNames', {
        credentialsNames: 'plaid-public-key,plaid-client-name,plaid-environment'
      })
      .then(({ results }) => {
        console.debug('getCredentialByNames=' + JSON.stringify(results))
        if (results) {
          this.plaidPublicKey = results.filter(
            f => f.credentialName === 'plaid-public-key'
          )[0].credentialValue
          this.plaidClientName = results.filter(
            f => f.credentialName === 'plaid-client-name'
          )[0].credentialValue
          this.plaidEnv = results.filter(
            f => f.credentialName === 'plaid-environment'
          )[0].credentialValue
        }
      })

    await bankDataProviderStore
      .dispatch('getProvider', {
        providerName: 'Plaid',
        isDefault: true
      })
      .then(({ result }) => {
        if (result) {
          this.defaultBankProviderId = result.bankDataProviderID

          console.debug('this.defaultBankProviderId=' + this.defaultBankProviderId)
        }
      })
  },

  async reload() {},
  onLoad() {},

  async onSuccess(public_token, metadata) {
    this.loading = true

    /*   
      // can get the 
      // plaid instituion id like this:
      const providerInstitutionID = _get(metadata, ['institution', 'institution_id'], null)
    */

    try {
      let bankName = metadata.institution.name

      let plaidAccounts = []

      if (Array.isArray(metadata.accounts)) {
        this.selectPlaidAccountIds(metadata.accounts)

        plaidAccounts = metadata.accounts.map(({ id: value, name: label }) => ({
          value,
          label
        }))

        console.debug('plaidaccounts=' + JSON.stringify(this.plaidAccountIds, null, 2))
      }

      if (metadata.institution.institution_id && bankName && public_token) {
        const {
          ret,
          financialInstitutionID,
          providerInstitutionID
        } = await this.createAccessTokenFromLinkToken(
          public_token,
          metadata.institution.institution_id,
          bankName
        )

        console.debug('ret=' + JSON.stringify(ret, null, 2))
        notifyMessage(`${bankName} was successfully linked to Plaid.`)

        this.onPlaidSuccess({
          plaidAccounts,
          institutionId: metadata.institution.institution_id,
          metadata,

          financialInstitutionID,
          providerInstitutionID
        })
      }
    } catch (e) {
      console.debug(e.Message)
      notifyError(e)
    }

    this.loading = false
  },

  onExit(err, metadata) {
    console.debug('err=' + err)
    console.debug('metadata=' + JSON.stringify(metadata, null, 2))
  },

  onEvent(eventName, metadata) {
    console.debug('Plaid Event:', eventName)
    console.debug('Plaid Event Metadata', JSON.stringify(metadata, null, 2))
  },

  async createAccessTokenFromLinkToken(publicToken, institutionId, bankName) {
    const hoaID = this.currentHoaId
    const bankDataProviderID = this.defaultBankProviderId
    const institutionID = institutionId

    console.log(
      'createAccessTokenFromLinkToken',
      {
        publicToken,
        institutionId,
        bankName
      },
      this
    )

    const { successful, message, data } = await createOrRetrievePlaidAccessToken({
      params: {
        hoaID,
        bankDataProviderID,
        institutionID,
        bankName,
        publicToken
      },
      json: {
        hoaID,
        bankDataProviderID,
        institutionID,
        bankName,
        publicToken
      }
    })
    if (!successful) {
      notifyError(message)
      console.log({ successful, data })
      return
    }

    const ret = data
    console.log({ ret })

    if (ret) {
      if (ret.financialInstitutionID) {
        this.selectFinancialInstitutionId(ret.financialInstitutionID)
        console.debug('finid=' + this.financialInstitutionId)
      }

      if (institutionId) {
        this.selectPlaidInstitutionId(institutionId)
        console.debug('plaidInsid=' + this.plaidInstitutionId)
      }
    }

    console.debug('ret=' + JSON.stringify(ret))

    return {
      ret,
      //
      financialInstitutionID: ret.financialInstitutionID,
      providerInstitutionID: ret.providerInstitutionID
    }
  }
}
