<template>
  <div @click="link_open" :style="{ display: 'inline' }">
    <slot></slot>
  </div>
</template>

<script>
/*
    fork of https://github.com/cyrusstoller/vue-plaid-link
    
    relevant:
        https://plaid.com/docs/sandbox/test-credentials/
*/

/*
    https://plaid.com/docs/link/web/
*/

import { data } from './keys/data'
import { props } from './keys/props'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'

export default {
  props,
  data,
  methods,
  mounted
}
</script>
