export const props = {
  clientName: String,
  env: {
    type: String,
    required: true,
    default: 'sandbox',
    validator: function(value) {
      // The value must match one of these strings
      return ['sandbox', 'development', 'production'].indexOf(value) !== -1
    }
  },
  isWebview: {
    type: Boolean,
    required: false
  },
  link_token: {
    type: String,
    required: false
  },
  public_key: {
    type: String,
    required: false
  },
  products: Array,
  receivedRedirectUri: {
    type: String,
    required: false
  },
  webhook: {
    type: String,
    required: false
  },
  onLoad: {
    type: Function,
    required: false
  },
  onSuccess: Function,
  onExit: {
    type: Function,
    required: false
  },
  onEvent: {
    type: Function,
    required: false
  }
}
