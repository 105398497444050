<template>
  <div class="plaid-link">
    <h3 :title="$t('plaidLink.listTitle')"></h3>

    <div class="columns">
      <div class="column is-4">
        <h5>Link to your bank using Plaid</h5>

        <p class="pb-5">
          Plaid will allow HOA My Way to link to your bank and retrieve actual records from you bank
          in order to reconcile checkbook entries.
        </p>

        <PlaidPortal
          :clientName="plaidClientName"
          :env="plaidEnv"
          :public_key="plaidPublicKey"
          :products="['transactions']"
          :onLoad="onLoad"
          :onSuccess="onSuccess"
          :onExit="onExit"
          :onEvent="onEvent"
        >
          <b-button class="is-primary">Open Plaid Link</b-button>
        </PlaidPortal>
      </div>
    </div>
  </div>
</template>

<script>
//import { PLAID_ENV, PLAID_PUBLIC_KEY, PLAID_CLIENT_NAME, DEFAULT_BANK_PROVIDER_ID } from '@/config'
//
import PlaidPortal from '@/components/Plaid/Portal'
//
import { computed } from './keys/computed'
import { methods } from './keys/methods'
//
export default {
  components: {
    PlaidPortal
  },

  props: {
    onPlaidSuccess: {
      type: Function,
      default: function() {}
    }
  },

  data: () => ({
    loading: false,
    plaidEnv: 'sandbox',
    plaidPublicKey: null,
    plaidClientName: null,
    defaultBankProviderId: null
  }),

  computed,
  methods,

  watch: {
    currentHoaId() {
      this.reload()
    }
  },

  async mounted() {
    await this.getCredentialByNames()
    this.reload()
  },

  i18n: {
    messages: {
      en: { plaidLink: { listTitle: 'Plaid Link' } }
    }
  }
}
</script>
