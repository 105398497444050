/*
  import { utilityStore } from '@/services/Utility/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { result } = await utilityStore.dispatch('getCredentialByName', {
      credentialName
    });
  */
  async getCredentialByName({}, { credentialName }) {
    try {
      console.debug('in getCredentialByName...')
      const result = await kms.get(`/Support/ThirdPartyCredentials/GetCredentialsSingle`, {
        params: credentialName
      })

      if (isDebug == true) console.debug('getCredentialByName=' + JSON.stringify(result))

      return {
        result: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving credentials by name.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getCredentialsMultiByNames({}, { credentialsNames }) {
    try {
      console.debug('in getCredentialByName...')
      const results = await kms.get(`/Support/ThirdPartyCredentials/GetCredentialsMulti`, {
        params: {
          credentialsNames
        }
      })

      if (isDebug == true)
        console.debug('getCredentialsMultiByNames=' + JSON.stringify(results.results))

      return {
        results: results.results
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving credentials by names.`)
      console.error(exception)
    }

    return {
      results: {}
    }
  }
}
Vue.use(Vuex)
export const utilityStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
