export function parseAccounts({ accounts }) {
  let parsed = {
    Asset: [],
    Income: []
  }

  for (let a = 0; a < accounts.length; a++) {
    const account = accounts[a]
    const { accountType } = account

    if (!Array.isArray(parsed[accountType])) {
      parsed[accountType] = []
    }

    parsed[accountType].push({
      value: account.accountId,
      label: `${account.accountNumber} - ${account.description}`
    })
  }

  return parsed
}
