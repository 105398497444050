/*
  import { getGLAccountsList } from '@/services/GeneralLedger/Account/List';

  //
  //  With account parsing
  //
  const { accounts, successful, message, parsedAccounts } = await getGLAccountsList ({
		parseTypes: true,
    params: {
      hoaID,
      
      // hasExpenseOnly
    }
  })
  if (!successful) {
    notifyProblem (message);
    throw new Error (message);
  }
  
  //
  //  Without account parsing
  //  
  const { accounts, successful, message } = await getGLAccountsList ({
		parseTypes: false,
    params: {
      hoaID
    }
  })
  if (!successful) {
    notifyProblem (message);
    throw new Error (message);
  }
  
  
*/

/*
    examples:
        parsedAccounts {
            Asset: [],
            Income: []
        }
        
        accounts [
        
        ]

*/

import _get from 'lodash/get'
import kms from '@/services/kms'

import { parseAccounts } from './_utilities/parseAccounts'

export async function getGLAccountsList({
  params,

  parseTypes = false
}) {
  let message = 'The general ledger account list could not be retrieved.'

  try {
    const result = await kms.get(`/GeneralLedger/Account/List`, {
      params
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      ...(parseTypes
        ? {
            parsedAccounts: parseAccounts({
              accounts: list
            })
          }
        : {}),
      //
      successful: true,
      message,
      accounts: list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}
